var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { defineComponent, ref, inject, computed, onBeforeMount, reactive, } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import DOMPurify from 'dompurify';
import PaginationWat from '@/components/PaginationWat.vue';
import CommentWhat from '@/components/CommentWhat.vue';
import CommentWhatLoading from '@/components/CommentWhatLoading.vue';
import CommentInputWhat from '@/components/CommentInputWhat.vue';
export default defineComponent({
    components: {
        PaginationWat: PaginationWat,
        CommentWhat: CommentWhat,
        CommentWhatLoading: CommentWhatLoading,
        CommentInputWhat: CommentInputWhat,
    },
    setup: function () {
        var _this = this;
        var whatStore = inject('whatStore');
        var pageSize = ref(10);
        var commentsPage = ref(1);
        var title = ref('');
        var backgroundImage = ref('');
        var post = ref({});
        var comments = ref([]);
        var postContentText = ref('');
        var totalComments = computed(function () {
            var numericCount = Number(whatStore.state.whatState.commentsCount[params.id]);
            return numericCount;
        });
        var comment = ref('');
        var route = useRoute();
        var router = useRouter();
        var params = reactive(route.params);
        var goToPostsList = function () {
            router.push({ name: 'what', replace: true });
        };
        var fetchCommentsCount = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, whatStore.getWhatPostsCommentsCount({ postIds: [params.id] })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var fetchComments = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = comments;
                        return [4 /*yield*/, whatStore.getWhatPostComments(params.id, commentsPage.value - 1, pageSize.value)];
                    case 1:
                        _a.value = _b.sent();
                        if (!comments.value) {
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        var createWhatComment = function (commentValue) { return __awaiter(_this, void 0, void 0, function () {
            var sanitizedComment, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sanitizedComment = DOMPurify.sanitize(commentValue);
                        if (sanitizedComment.trim() === "")
                            return [2 /*return*/];
                        data = {
                            comment: sanitizedComment,
                        };
                        return [4 /*yield*/, whatStore.createWhatComment(params.id, data)];
                    case 1:
                        _a.sent();
                        fetchComments();
                        return [2 /*return*/];
                }
            });
        }); };
        var rateCommentPositive = function (commentId) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, whatStore.rateWhatCommentPositive(commentId)];
                    case 1:
                        _a.sent();
                        fetchComments();
                        return [2 /*return*/];
                }
            });
        }); };
        var rateCommentNegative = function (commentId) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, whatStore.rateWhatCommentNegative(commentId)];
                    case 1:
                        _a.sent();
                        fetchComments();
                        return [2 /*return*/];
                }
            });
        }); };
        var replyToComment = function (parentCommentId, comment) { return __awaiter(_this, void 0, void 0, function () {
            var sanitizedComment, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sanitizedComment = DOMPurify.sanitize(comment);
                        if (sanitizedComment.trim() === "")
                            return [2 /*return*/];
                        data = {
                            comment: sanitizedComment,
                            answerTo: parentCommentId,
                        };
                        return [4 /*yield*/, whatStore.replyToComment(params.id, data)];
                    case 1:
                        _a.sent();
                        fetchComments();
                        return [2 /*return*/];
                }
            });
        }); };
        onBeforeMount(function () { return __awaiter(_this, void 0, void 0, function () {
            var data, _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, whatStore.getWhatPost(params.id)];
                    case 1:
                        data = _d.sent();
                        post.value = data;
                        _a = title;
                        return [4 /*yield*/, post.value.title.rendered];
                    case 2:
                        _a.value = _d.sent();
                        _b = backgroundImage;
                        return [4 /*yield*/, post.value.better_featured_image.media_details.sizes
                                .medium_large.source_url];
                    case 3:
                        _b.value =
                            (_d.sent()) ||
                                post.value.better_featured_image.media_details.sizes.large
                                    .source_url;
                        _c = postContentText;
                        return [4 /*yield*/, post.value.content.rendered];
                    case 4:
                        _c.value = _d.sent();
                        return [4 /*yield*/, fetchCommentsCount()];
                    case 5:
                        _d.sent();
                        return [4 /*yield*/, fetchComments()];
                    case 6:
                        _d.sent();
                        return [2 /*return*/];
                }
            });
        }); });
        return {
            goToPostsList: goToPostsList,
            createWhatComment: createWhatComment,
            rateCommentPositive: rateCommentPositive,
            rateCommentNegative: rateCommentNegative,
            replyToComment: replyToComment,
            fetchComments: fetchComments,
            totalComments: totalComments,
            title: title,
            backgroundImage: backgroundImage,
            comments: comments,
            commentsPage: commentsPage,
            postContentText: postContentText,
            comment: comment,
            whatStore: whatStore
        };
    },
});

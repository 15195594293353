import { defineComponent, ref } from 'vue';
export default defineComponent({
    emits: ['createWhatComment'],
    props: {
        placeholder: {
            type: String,
            default: null
        }
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var comment = ref('');
        var createWhatComment = function () {
            if (comment.value.trim() === "")
                return;
            emit('createWhatComment', comment.value);
            comment.value = '';
        };
        return {
            comment: comment,
            createWhatComment: createWhatComment
        };
    },
});
